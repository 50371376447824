<template>
  <div>
    <WidgetsDropdown/>
    <CRow>
      <CCol md="12">
        <TableVisitRequest />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="6">
        <CCard>
          <CCardHeader color="warning">
            On-Going Progress &amp; Estimation
          </CCardHeader>
          <CCardBody style="min-height: 355px;">
            <CRow>
              <CCol sm="12" lg="12">
                <CRow>
                  <CCol sm="4">
                    <CCallout color="info">
                      <small class="text-muted">DC TB Simatupang</small><br>
                      <strong class="h4">{{site1Count}}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="danger">
                      <small class="text-muted">DC Taman Tekno</small><br>
                      <strong class="h4">{{site2Count}}</strong>
                    </CCallout>
                  </CCol>
                  <CCol sm="4">
                    <CCallout color="success">
                      <small class="text-muted">DC Jatiluhur</small><br>
                      <strong class="h4">{{site3Count}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0">
                <h5 v-if="onprogress.length === 0">No On-Going Visit.</h5>
                <WidgetsProgressVisit v-for="item in onprogress" :key="item.id"
                  :id="item.id"
                  :company="item.user_id"
                  :activity="item.activity_name"
                  :site="item.site_name"
                  :room="item.room_name"
                  :date = "new Date(item.end_date)"
                />
              </CCol>
              
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="6">
        <CCard>
          <CCardHeader color="info">
            Jumlah Kunjungan per Data Center (YTD)
          </CCardHeader>
          <CCardBody>
            <CChartDoughnut
              :datasets="defaultDatasets"
              :labels="labels"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import http from '@/utils/http-common';

import WidgetsDropdown from './widgets/WidgetsDropdown';
import WidgetsProgressVisit from './widgets/WidgetsProgressVisit';
import { CChartDoughnut } from '@coreui/vue-chartjs'
import TableVisitRequest from './tables/VisitRequest.vue';
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    WidgetsDropdown,
    WidgetsProgressVisit,
    CChartDoughnut,
    TableVisitRequest
  },
  data () {
    return {
      login: localStorage.getItem('user'),
      selected: 'Month',
      dataSets: [{
        data: [],
        backgroundColor: []
      }],
      labels: []
    }
  },
  methods: {
    getDc(id) {
      console.log(id)
    },
    getVisits() {
      let self = this;
      return http.get('/visits')
			  .then(function (response) {
				  const list = response.data.data;
          let site1 = list.filter(function(item) {
    			  return (item.site_name === 'DC TB Simatupang');  
				  }).length;
          let site2 = list.filter(function(item) {
    			  return (item.site_name === 'DC Taman Tekno');  
				  }).length;
          let site3 = list.filter(function(item) {
    			  return (item.site_name === 'DC Jatiluhur');  
				  }).length;
          
          if (site1 > 0) {
            self.dataSets[0].data.push(site1);
            self.dataSets[0].backgroundColor.push('#41B883');
            self.labels.push('DC TB Simatupang');
          }
          if (site2 > 0) {
            self.dataSets[0].data.push(site2);
            self.dataSets[0].backgroundColor.push('#E46651');
            self.labels.push('DC Taman Tekno');
          }
          if (site3 > 0) {
            self.dataSets[0].data.push(site3);
            self.dataSets[0].backgroundColor.push('#00D8FF');
            self.labels.push('DC Jatiluhur');
          }

          self.$store.dispatch('notified')
				    .then(() => {
              //
				    })
				    .catch(function (error) {
        	    console.log(error);
      	    });

            // run after 3 minutes  
            setInterval(function() {
              // your code goes here...
              self.$store.dispatch('notified')
				        .then(() => {
					        // 
				        })
				        .catch(function (error) {
        	        console.log(error);
      	        });
            }, 180 * 1000); // every 3 minutes
          
          // console.log(self.dataSets)
			  }).catch(function (error) {
				  console.log(error);
			  });
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['onprogress']),
    defaultDatasets () {
      return this.dataSets
    },
    site1Count () {
      return this.$store.state.onprogress.filter(list => list.site_name === 'DC TB Simatupang').length;
    },
    site2Count () {
      return this.$store.state.onprogress.filter(list => list.site_name === 'DC Taman Tekno').length;
    },
    site3Count () {
      return this.$store.state.onprogress.filter(list => list.site_name === 'DC Jatiluhur').length;
    }
  },
  mounted() {
    this.getVisits();
  },
}
</script>
