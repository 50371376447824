<template>
  <div>
    <div class="progress-group">
      <div class="progress-group-header">
        <CIcon name="cil-speedometer" class="progress-group-icon"/>
        <span class="title">{{company}} &#183; <small>{{activity}}</small>
          <small><br> <strong>{{site}}</strong> &#183; {{room}}</small> <span class="text-muted small">({{percentage}}%)</span> 
        </span>
        <span class="ml-auto font-weight-bold" v-if="timer === true">
          {{hours}}:{{minutes}}:{{seconds}}
        </span>
        <span style="color: red; cursor: pointer;" class="ml-auto font-weight-bold" v-if="timer === false">
          <a class="animate__animated animate__flash animate__infinite" @click="goTo('/approval/visit-request/' + id)">Must Extend?</a>
        </span>
      </div>
      <div class="progress-group-bars">
        <CProgress
          class="progress-xs"
          :value="Number(percentage)"
          :color="color(percentage)"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WidgetsProgressVisit',
  data () {
		return {
      timer: true,
      now: '00:00:00',
      time: '00:00:00',
      percentage: 0,
      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervals: {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
    }
  },
  props:{
    id:{
      required:true
    },
    company:{
      required:true
    },
    activity:{
      required:true
    },
    site:{
      required:true
    },
    room:{
      required:true
    },
    date:{
      required:true
    },
  },
  methods: {
    goTo(url) {
      this.$router.push({ path: url });
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    updateDiffs(date) {
      //lets figure out our diffs
      const dt = new Date(date);
      let diff = Math.abs(Date.now() - date.getTime());

      if ( dt.getTime() < new Date().getTime() ) {
        this.timer = false;
      } else {
        this.days = String(Math.floor(diff / this.intervals.day)).padStart(2, '0');
        diff -= this.days * this.intervals.day;
        this.hours = String(Math.floor(diff / this.intervals.hour)).padStart(2, '0');
        diff -= this.hours * this.intervals.hour;
        this.minutes = String(Math.floor(diff / this.intervals.minute)).padStart(2, '0');
        diff -= this.minutes * this.intervals.minute;
        this.seconds = String(Math.floor(diff / this.intervals.second)).padStart(2, '0');

        this.now = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
        this.percentage = (100 * this.totalSeconds(this.now) / this.totalSeconds(this.time)).toFixed(2);
      }
    },
    totalSeconds(time) {
      let parts = time.split(':');
      return +parts[0] * 3600 + +parts[1] * 60 + +parts[2];
    }
  },
  computed: {
    
  },
  mounted() {
    this.timer = true;
    this.time = this.date.getHours() + ':' + this.date.getMinutes() + ':' + this.date.getSeconds();
    this.interval = setInterval(() => {
      this.updateDiffs(this.date);
    },1000);
    
    this.updateDiffs(this.date);
  },
  destroyed() {
    clearInterval(this.interval);    
  },
}
</script>